// eslint-disable-next-line
export default [
  {
    section: "Data",
    fields: [
      {
        name: "clientIDClientHasAutoInsurance",
        text: "Auto Insurance?",
        type: "string",
        options: ["Yes", "No"],
        default: 'Yes'
      },
      {
        name: "clientIDWantsToInputPolicyData",
        text: "Client wants to input policy data?",
        type: "string",
        options: ["Yes", "No"],
        default: 'Yes'
      },
      {
        name: "clientIDAssetName",
        text: "clientIDAssetName",
        type: "string",
      },
      {
        name: "clientIDAutoInsuranceCompany",
        text: "Auto Insurance provider",
        type: "string",
        options: "provider",
        default: 'Yes'
      },
      {
        name: "clientIDHomeInsuranceCompany",
        text: "Home Insurance provider",
        type: "string",
        options: "provider",
      },
    ],
  },

  {
    section: "Manual Input",
    fields: [
      {
        name: "clientIDtotalPremiumAuto",
        text: "Total annual premium:",
        type: "number",
      },
      {
        name: "clientIDbodilyInjuryPerPerson",
        text: "Bodily injury per person covered:",
        type: "number",
      },
      {
        name: "clientIDbodilyInjuryPerOcurrence",
        text: "Bodily injury per occurrence covered:",
        type: "number",
      },
      {
        name: "clientIDpropertyDamagePerOccurrence",
        text: "Property damage per occurrence covered:",
        type: "number",
      },
      {
        name: "clientIDHasBasicPersonalInjuryProtection",
        text: "Does the policy have Personal Injury Protection (PIP) coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDbasicPersonalInjuryProtection",
        text: "Basic personal injury protection covered:",
        type: "number",
      },
      {
        name: "clientIDaddedPersonalInjuryProtection",
        text: "Added personal injury protection covered:",
        type: "number",
      },
      {
        name: "clientIDsearchMedicalPaymentsPerPersonPerOccurrence",
        text: "Medical payments per person coverage:",
        type: "number",
      },
      {
        name: "clientIDsearchOptionalBasicEconomicLossOBEL",
        text: "Optional Basic Economic Loss (OBEL):",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDsupplementaryUninsured",
        text: "Does the policy include Supplementary Uninsured/Underinsured Motorist Coverage (SUM)?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDcoverageOneCollision",
        text: "Does the policy have collision insurance?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDPremiumForCollisionCoverage",
        text: "Annual premium for collision coverage:",
        type: "number",
      },
      {
        name: "clientIDPartDdeductible",
        text: "Collision deductible:",
        type: "number",
      },
      {
        name: "clientIDcoverageOtherThanCollision",
        text: "Does the policy include comprehensive / other than collision coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDPremiumForComprehensiveCoverage",
        text: "Annual premium for comprehensive / other than collision coverage:",
        type: "number",
      },
      {
        name: "clientIDdeductibleOtherThanCollision",
        text: "Comprehensive / other than collision deductible:",
        type: "number",
      },
      {
        name: "clientIDgapInsurance",
        text: "Does the client have gap insurance?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDleaseGap",
        text: "Does the client have lease gap insurance?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDtowingLabor",
        text: "Does the policy have towing and labor coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDemergencyRoadsideAssistance",
        text: "Does the policy have emergency roadside assistance coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDrentalCarReinbursment",
        text: "Does the policy have rental car reimbursement coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDglassCoverage",
        text: "Does the policy have glass coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDGlassDeductible",
        text: "Glass deductible:",
        type: "number",
      },
      {
        name: "clientIDlossPayeeAuto",
        text: "Does the policy include a loss payee?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDlessor",
        text: "Does the policy include a lessor?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDOEMendorsement",
        text: "Does the client have Original Equipment Manufacturer (OEM) coverage?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDmultipleAutoPolicies",
        text: "Does the client have multiple policies?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDgarageState",
        text: "In which state is the vehicle garaged?",
        type: "string",
        //options: ["Yes", "No"], //list of states
      },


      {
        name: "clientIDClientHasUmbrellaDocument",
        text: "Does the client have an umbrella policy?", 
        type: "string",
        options: ["Yes", "No"],
      },
      //REVAMP not currently used
      /*{
        name: "clientIDUmbrellaTrust",
        text: "Does the Trust name appear on the policy?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDTrustEndorsement",
        text: "Does a Trust Endorsement or the name of a Trust appear on the policy?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDJointOwnership",
        text: "Does the policy list a Joint Ownership Coverage endorsement?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDJointOwnersAuto",
        text: "Joint owners names in Auto policy:", 
        type: "string",
      },
      {
        name: "clientIDJointOwnersUmbrella",
        text: "Joint owners names in Umbrella policy:", 
        type: "string",
      },
      {
        name: "clientIDLiabilityExclusion",
        text: "Does the policy list a Liability Coverage Exclusion Endorsement?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDCoverageDamageExclusion",
        text: "Does the policy list a Coverage for Damage to Your Auto Exclusion Endorsement?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDVehicleSharingExclusion",
        text: "Does the policy list a Personal Vehicle Sharing Program Exclusion Endorsement?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDLiveryConveyanceExclusion",
        text: "Does the policy list Public or Livery Conveyance Exclusion Endorsement?", 
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDAdditionalResident",
        text: "Does the policy list Additional Resident to Your Household Endorsement?", 
        type: "string",
        options: ["Yes", "No"],
      },*/

      //* New
      {
        name: "clientIDClientLeases",
        text: "Does the client lease?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDHasAutoLoan",
        text: "Does the client have an auto loan?",
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDCarsOwned",
        text: "clientIDCarsOwned", //FIXME no exist text in the fronend
        type: "number",
      },
      {
        name: "clientIDUninsuredMotoristprotectionCoverageBeingConsidered",
        text: "Is the client considering uninsured/underinsured motorist protection coverage?",
        //text: 'Are you considering uninsured/underinsured motorist protection coverage?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDClientConsideringCollisionCoverage",
        text: "Is the client considering to purchase collision coverage?",
        //text: 'Are you considering to purchase collision coverage?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDClientConsideringOtherThanCollision",
        text: "Is the client considering to purchase comprehensive (other than collision) coverage?",
        //text: 'Are you considering to purchase comprehensive (other than collision) coverage?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDNeedinganotherVehicle",
        text: "Would the client need another vehicle in the event that his/hers was being repaired after a covered collision or comprehensive loss?",
        //text: 'Would you need another vehicle in the event that yours was being repaired after a covered collision or comprehensive loss?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDConsideringFullCoverageForDamage",
        text: "Is the client considering full coverage for damage to automobile glass?",
        //text: 'Are you considering full coverage for damage to automobile glass?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDAllVehiclesNotOnSamePolicy",
        text: "Will all of the client's vehicles be on the same policy?",
        //text: 'Will all of your vehicles be on the same policy?',
        type: "string",
        options: ["Yes", "No"],
      },
      {
        name: "clientIDClientWillingToSelfInsureToHighLevel",
        text: "Is the client willing to self-insure to a high level?",
        //text: "Are you willing to self-insure to a high level?",
        type: "string",
        options: ["Yes", "No"],
      },
    ],
  },

  /* {
    section: "About your Policy",
    fields: [
      {
        name: "clientIDClientDrivesAndDoesNotOwnCar",
        text: "Does the client own the car he/she drives?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },

      {
        name: "clientIDCarLastTimeShopped",
        text: "When was the policy last shopped?",
        type: "number",
        default: "0",
      },
      {
        name: "clientIDInsurancePurchase",
        text: "Did the client purchase his/her insurance through an employer, professional association or alumni association?",
        type: "string",
        default: " "
      },
      {
        name: "clientIDCreditsAndDiscountsAuto",
        text: "Is the client aware of all the discounts his/her insurance company offers, and has the client made sure he/she has have received all that apply?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },

      {
        name: "clientIDCarsOnSamePolicy",
        text: "Are all of the client's cars, motorcycles or RVs on the same policy?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },
      {
        name: "clientIDHasAAA",
        text: "Does the client have a AAA (American Automobile Association) membership?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },
      {
        name: "clientIDLowMileage",
        text: "Approximately how many miles does the client drive per year?",
        type: "number",
        default: "0",
      },
    ],
  }, */

  /* {
    section: "About",
    fields: [
      {
        name: "clientIDCarAge1", //! I think this is a N fields in DB there are 1, 2 and 3
        text: "What is the model year of the the client's car?",
        type: "number",
        default: "0",
      },
      {
        name: "clientIDCarValue1", //! I think this is a N fields in DB there are 1, 2 and 3
        text: "What is the estimated value of the client's car?",
        type: "number",
        default: "0",
        line: "For a sense of the car’s value you can visit Kelley Blue Book’s site: https://www.kbb.com",
      },
      {
        name: "clientIDWouldRepairCar1", //! I think this is a N fields in DB there is 1
        text: "Would the client pay for repairs if his/her car was damaged?",
        type: "string",
        options: ["Yes", "No", "undefined"],
        default: "undefined",
      },
    ],
  }, */
  
];