/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { AddCreditsModal } from '../../modal/AddCreditsModal';
import { fetchCurrentCompanyCredits } from '../../../actions/company.actions';
import { fetchCurrentCompany } from '../../../actions/company.actions';
import { Grid, Card, Segment, Header, Button } from 'semantic-ui-react';
import ChangeUploadModeType from './ChangeUploadModeType';
import BuyCredits from './credits/BuyCredits';

const CreditsCard = ({ type, data }) => {
  return (
    <Card>
      <Card.Content>
        <Card.Header textAlign="center" style={{ marginBottom: '1.2em', marginTop: '0.6em' }}>{type || ''}</Card.Header>
        <Card.Description>
          <Header as="h2" textAlign="center" style={{ marginBottom: '1.2em' }}>{data?.remaining || '0'}/{data?.total || '0'}</Header>
          <Grid centered style={{ marginBottom: '0.6em' }}>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column width={5}>Free:</Grid.Column>
              <Grid.Column width={5}>{data?.free?.remaining > 10000 ? '+10K' : data?.free?.remaining || '0'}/{data?.free?.total > 10000 ? '+10K' : data?.free?.total || '0'}</Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column width={5}>Credits:</Grid.Column>
              <Grid.Column width={5}>{data?.credits?.remaining > 10000 ? '+10K' : data?.credits?.remaining || '0'}/{data?.credits?.total > 10000 ? '+10K' : data?.credits?.total || '0'}</Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: 0 }}>
              <Grid.Column width={5}>Purchased:</Grid.Column>
              <Grid.Column width={5}>{data?.purchased?.remaining > 10000 ? '+10K' : data?.purchased?.remaining || '0'}/{data?.purchased?.total > 10000 ? '+10K' : data?.purchased?.total || '0'}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

const CompanyCreditsGeneral = ({ activeMenu }) => {
  const params = useParams();
  const me = useSelector(state => state.me);
  const { companyId } = params;
  const allCredits = useSelector((state) => state.currentCompanyCredits);
  const company = useSelector((state) => state.currentCompany);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  const modelTypesOptions = {
    legacy :'Legacy',
    adoption: 'Part Limited',
    limited: 'Full Limited',
};

  useEffect(() => {
    if (!openModal) {
      dispatch(fetchCurrentCompanyCredits(companyId, 'all'));
      dispatch(fetchCurrentCompany(companyId));
    }
  }, [openModal]);

  return (<>
    {activeMenu === 'creditsGeneral' && <>
      <Segment.Group horizontal>
        <Segment><strong>Upload Model:</strong> {modelTypesOptions[company.uploadModel.type] || 'N/A'}</Segment>
        {/* <Segment><strong>Mode:</strong> {allCredits?.fulltax?.uploadModelMode || 'N/A'}</Segment> */}
      </Segment.Group>

      <Segment basic clearing style={{ paddingTop: 0, paddingRight: 0 }} className='flex-fpalpha justify-content-end align-items-center gap-5'>
        <div>
          <ChangeUploadModeType company={company} />
        </div>
        {me.permissions?.includes("companies:add_credits") &&
          <div>
            <Button color="blue" onClick={() => setOpenModal(true)}>Add credits</Button>
          </div>
        }
        <div>
          <BuyCredits company={company} />
        </div>
      </Segment>

      <Header>Summary</Header>

      <Grid>
        <Grid.Row>
          <Grid.Column width={4}>
            <CreditsCard type="Fulltax" data={allCredits?.fulltax} />
          </Grid.Column>

          <Grid.Column width={4}>
            <CreditsCard type="Estate" data={allCredits?.estate} />
          </Grid.Column>

          <Grid.Column width={4}>
            <CreditsCard type="Home" data={allCredits?.home} />
          </Grid.Column>

          <Grid.Column width={4}>
            <CreditsCard type="Auto" data={allCredits?.auto} />
          </Grid.Column>

        </Grid.Row>
      </Grid>

      <AddCreditsModal modalState={openModal} setModalState={setOpenModal} />
    </>}
  </>);
}

export default CompanyCreditsGeneral;
