import { useState, useEffect } from 'react';
import { sysAPI } from '../../apis/SysAPI';
import { advisorAPI } from '../../apis/AdvisorsAPI';
import moment from 'moment';
import { Modal, Radio, Message, Segment, Button, Form, Dropdown, Input } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";

export const AddCompanyModal = ({ modalState, setModalState }) => {
  const history = useHistory();
  const [type, setType] = useState('trial');
  const [companies, setCompanies] = useState([]);
  const [pocs, setPocs] = useState([]);
  const [company, setCompany] = useState([]);
  const [poc, setPoc] = useState('');
  const [showPocInput, setShowPocInput] = useState(false);
  const [modelType, setModelType] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [renewal, setRenewal] = useState('');
  const [contractEndDate, setContractEndDate] = useState('');

  const modelTypesOptions = [
    {
      text: 'Legacy: Unlimited Tax, pay per Estate/Auto/Home Snapshot',
      value: 'legacy',
      // disabled: true,
    },
    {
      text: 'Part Limited: Firmwide Adoption Unlimited Tax, 10 Estate/5 Auto/5 Home',
      value: 'adoption',
    },
    {
      text: 'Full Limited: 35 Tax/ 10 Estate/ 5 Auto/ 5 Home',
      value: 'limited',
    },
  ];

  const cancelButton = () => {
    setType('trial');
    setCompany('');
    setPoc('');
    setShowPocInput(false);
    setModelType('');
    setContactName('');
    setContactEmail('');
    setModalState(false);
  }

  const addCompany = async () => {
    const body = {
      name: company,
      poc: poc,
      modelType: type,
      uploadModelType: modelType,
      renewalDate: type === 'purchased' ? renewal : moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD').toString(),
      contractEndDate: type === 'purchased' ? contractEndDate : moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD').toString(),
      logo: 'https://storage.googleapis.com/fpalpha-assets/company%20logos/fpalpha/fpalpha1_CMYK.png',
      mainContact: {
        name: contactName,
        email: contactEmail,
      },
    };
    try {
      const {data:company} = await advisorAPI.post('companies/', body);
      console.log({company});
      if(company?._id){
        // eslint-disable-next-line no-restricted-globals
        const goCompany = confirm(`The company "${company.name}" was created. Do you want view the profile?`);
        if(goCompany){
          history.push(`/dashboard/companies/${company?._id}`);
        }
      }

      cancelButton();
    } catch (error) {
      console.log({error});
      const messageError = error?.response?.data?.message || error?.message || "Oops... Was an error, try again please"
      alert(`Error: ${messageError}`);
    }
  };

  useEffect(() => {
    sysAPI.get('/companies').then(response => {
        setCompanies(response.data.companiesList.map(itm => ({ key: itm, value: itm, text: itm})))
    });

    sysAPI.get('/companies/pocs').then(response => {
        setPocs(response.data.map(itm => ({ key: itm?._id || itm?.name, value: itm?._id || itm?.name, text: itm?.name })));
    });
  }, []);

  return (
    <Modal size='tiny' open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
      <Modal.Header>Create Company</Modal.Header>

      <Modal.Content>
        <Segment>
          <Form>
            <Form.Group style={{marginBottom: 0}}>
              <Form.Field control={Radio} label="Trial" checked={type === 'trial'} onChange={() => setType('trial')} />
              <Form.Field control={Radio} label="Purchased" checked={type === 'purchased'} onChange={() => setType('purchased')} />
            </Form.Group>

            {type === 'trial' && <>
              <Message color="teal">
                This company will have 10 days of trial version.
              </Message>
            </>}
          </Form>
        </Segment>

        {type === 'purchased' &&
          <>
            <Segment>
              <div style={{ width: '100%' }}>
                <Form.Field
                  control={Input}
                  type="date"
                  fluid
                  label="Renewal date"
                  value={renewal}
                  onChange={(_, val) => setRenewal(val.value)}
                  min={moment().add(1, 'day').format('YYYY-MM-DD').toString()}
                />
              </div>
            </Segment>
            <Segment>
              <div style={{ width: '100%' }}>
                <Form.Field
                  control={Input}
                  type="date"
                  fluid
                  label="Contract End Date"
                  value={contractEndDate}
                  onChange={(_, val) => setContractEndDate(val.value)}
                  min={moment().add(1, 'day').format('YYYY-MM-DD').toString()}
                />
              </div>
            </Segment>
          </>
        }

        <Segment>
          <Form>
            <Dropdown
              placeholder="Company"
              className="icon create-company-custom-search"
              options={companies}
              value={company}
              onChange={(_, val) => { setCompany(val.value); setShowPocInput(true); }}
              icon='building'
              labeled
              button
              fluid
              selection
              search
              allowAdditions
              onAddItem={(_, val) => {setCompanies(prev => ([...prev, {key: val.value, value: val.value, text: val.value}]))}}
            />

            {showPocInput && <div style={{ margin: '14px 0 0 36px' }}>
              <Dropdown
                placeholder="POC"
                className="icon create-company-custom-search"
                options={pocs}
                value={poc}
                onChange={(_, val) => { setPoc(val.value) }}
                icon='user'
                labeled
                button
                fluid
                selection
                search
                allowAdditions
                onAddItem={(_, val) => {setPocs(prev => ([...prev, {key: val.value, value: val.value, text: val.value}]))}}
              />
            </div>}
          </Form>
        </Segment>

        <Segment>
          <Dropdown
            placeholder="Model type"
            className="icon"
            options={modelTypesOptions}
            value={modelType}
            onChange={(_, val) => setModelType(val.value)}
            icon='tag'
            labeled
            button
            fluid
            selection
            search
          />
        </Segment>

        <Segment>
          <h4>Main Contact</h4>
          <Form>
            <div style={{ width: '100%', marginBottom: '12px' }}>
              <Form.Field control={Input} fluid label="Name" value={contactName} onChange={(_, val) => setContactName(val.value)} />
            </div>
            <div style={{ width: '100%' }}>
              <Form.Field control={Input} fluid type="email" label="Email" value={contactEmail} onChange={(_, val) => setContactEmail(val.value)} />
            </div>
          </Form>
        </Segment>
      </Modal.Content>

      <Modal.Actions>
        <Button onClick={cancelButton}>Cancel</Button>
        <Button
          color="green"
          onClick={addCompany}
          disabled={!(!!(
            company
            && modelType
            && poc
            && contactName
            && contactEmail
            && (type === 'trial' || (type === 'purchased' && renewal))
          ))}
        >Create account</Button>
      </Modal.Actions>
    </Modal>
  );
};
