import { Icon, Popup, Table, Button } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { prettyDate } from "../../../helpers/moment-helpers";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { copyToClipboard } from "../../../helpers";
import { AddAdvisorModal } from "../../modal/AddAdvisorModal";

const CompanyAdvisorsTab = ({ activeTab }) => {
  const history = useHistory();
  const me = useSelector(state => state.me);
  
  const companyProfile = useSelector((state) => state.currentCompany);
  const [showTabActions, setShowTabActions] = useState(false);
  const [isCopied, setIsCopied] = useState(false)
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const hasPermissionsToShowActions = me?.permissions?.filter(p => ["advisors:copy_email", "advisors:copy_id"].includes(p)) || []
    if(hasPermissionsToShowActions.length > 0){
        setShowTabActions(true)
    }
  },[me])

  return (
    <div>
      {activeTab === "advisors" && (
        <div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3>
              All Advisors ({companyProfile?.advisorList?.length})
            </h3>
            <div>
              {me?.permissions?.includes("advisors:add_advisor") &&
                <Button color="blue" onClick={() => setShowModal(true)}>
                  Add Advisor
                </Button>
              }
            </div>
          </div>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Full Name</Table.HeaderCell>
                <Table.HeaderCell>E-mail</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                {/* <Table.HeaderCell>POC</Table.HeaderCell> */}
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>2FA</Table.HeaderCell>
                <Table.HeaderCell>License Expires</Table.HeaderCell>
                <Table.HeaderCell>Last Login</Table.HeaderCell>
                {showTabActions && <>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                </>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {companyProfile?.advisorList?.map((advisor, index) => (
                <Table.Row key={index}>
                  <Table.Cell>
                    <div style={{cursor: 'pointer'}} onClick={() => history.push(`/dashboard/advisors/${advisor._id}`)}>
                      {advisor?.fullName}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{advisor?.email}</Table.Cell>
                  <Table.Cell>{advisor?.role?.name}</Table.Cell>
                  {/* <Table.Cell>{companyProfile?.poc?.fullName}</Table.Cell> */}
                  <Table.Cell>{advisor?.status}</Table.Cell>
                  <Table.Cell>
                    {advisor?.hasEnabled2FA ? "Active" : "Inactive"}
                  </Table.Cell>
                  <Table.Cell>
                    {prettyDate({ date: advisor?.licenseExpiresIn })}
                  </Table.Cell>
                  <Table.Cell>
                    {prettyDate({ date: advisor?.activity?.lastLogin })}
                  </Table.Cell>
                  {showTabActions && <>
                    <Table.Cell>
                        {me?.permissions?.includes("advisors:copy_email") && <>
                            <Popup
                                on={['hover']}
                                trigger={
                                    <Icon name='at' style={{cursor:"pointer"}} size="large" onClick={() => {
                                        copyToClipboard(`${advisor.email}`, false)
                                        setIsCopied(true)
                                        setTimeout(() => {
                                            setIsCopied(false)
                                        }, 1000)
                                    }} />
                                }
                                content={isCopied ? 'Copied': 'Click to copy Email'}
                                inverted
                            />
                        </>}
                        {me?.permissions?.includes("advisors:copy_id") && <>
                            <Popup
                                on='hover'
                                trigger={
                                    <Icon name='barcode' style={{cursor:"pointer"}} size="large" onClick={() => {
                                        copyToClipboard(`${advisor._id}`, false)
                                        setIsCopied(true)
                                        setTimeout(() => {
                                            setIsCopied(false)
                                        }, 1000)
                                    }} />
                                }
                                content={isCopied ? 'Copied': 'Click to copy ID'}
                                inverted
                            />
                        </>}
                    </Table.Cell>
                </>}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <AddAdvisorModal
            modalState={showModal}
            setModalState={(val) => setShowModal(val)}
            preselectedCompany={companyProfile?.name}
            companyType={companyProfile?.modelType}
          />

        </div>
      )}
    </div>
  );
};

export default CompanyAdvisorsTab;
