/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { fetchCurrentCompany, fetchCurrentCompanyCredits, fetchCurrentCompanyRequests } from "../../actions/company.actions";
import { useParams } from "react-router-dom";
import { DashboardTemplate } from "../../templates/DashboardTemplate"
import { Segment } from 'semantic-ui-react'
import CompanySideMenu from "../../components/tabs/company/SideMenu";
import CompanyGeneralInfo from "../../components/tabs/company/GeneralInfo";
import CompanyAdvisorsTab from "../../components/tabs/company/AdvisorsTab";
import CompanyEstateSnapshotTab from "../../components/tabs/company/EstateSnapshotTab";
import CompanyCreditsGeneral from "../../components/tabs/company/CreditsGeneral";
import CompanyPurchasedCredits from "../../components/tabs/company/PurchasedCredits";
import CompanyRequestedCredits from "../../components/tabs/company/RequestedCredits";
import IntegrationSalesforce from "../../components/tabs/company/IntegrationSalesforce";

const CompanyProfile = () => {
    const params = useParams();
    const { companyId } = params;
    const [activeMenu, setActiveMenu] = useState('general')
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log({CompanyProfile: companyId})
        dispatch(fetchCurrentCompany(companyId));
        dispatch(fetchCurrentCompanyCredits(companyId, 'all'));
        dispatch(fetchCurrentCompanyRequests(companyId));
    }, []);

    return (
        <DashboardTemplate scope='companies' permission="companies:company_view">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gridAutoRows: '1fr',
              columnGap: '1em',
            }}
          >
            <CompanySideMenu activeMenu={activeMenu} changeMenu={setActiveMenu} />

            <Segment style={{ margin: 0 }}>
              <CompanyGeneralInfo activeMenu={activeMenu} />
              <CompanyAdvisorsTab activeTab={activeMenu} />
              <CompanyEstateSnapshotTab activeTab={activeMenu} />
              <CompanyCreditsGeneral activeMenu={activeMenu} />
              <CompanyPurchasedCredits activeMenu={activeMenu} />
              <CompanyRequestedCredits activeMenu={activeMenu} />

              <IntegrationSalesforce activeMenu={activeMenu} />
            </Segment>
          </div>
        </DashboardTemplate>
    )
}

export default CompanyProfile