import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchCurrentAdvisorProfile, setCurrentAdvisorActivity, setCurrentAdvisorClients, setCurrentAdvisorProfile } from "../actions/advisor.actions";
import { DashboardTemplate } from "../templates/DashboardTemplate"
import { Button, Icon, Header, Segment, Input } from 'semantic-ui-react'
import { AdvisorMenu } from "../components/menu/AdvisorMenu";
import { AdvisorClientsTab } from "../components/tabs/AdvisorClientsTab";
import { AdvisorOverviewTab } from "../components/tabs/AdvisorOverviewTab";
import { ConfirmAdvisorActionModal } from "../components/modal/ConfirmAdvisorActionModal";
import { AdvisorActivityTab } from "../components/tabs/AdvisorActivityTab";
import { sysAPI } from "../apis/SysAPI";
import { fpalphaAPI } from "../apis/FPAlphaAPI";
import EnableDisable2Fa from "../components/tabs/advisors/EnableDisable2Fa";
import EnableDisableFeatures from "../components/tabs/advisors/EnableDisableFeatures";
import ChangeNameOrEmail from "../components/tabs/advisors/ChangeNameOrEmail";

export const AdvisorProfileView = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('Overview');
    const selectFPClientFileInput = useRef();
    const [loaderUpload, setLoaderUpload] = useState(false)

    const advisorProfile = useSelector(state => state.currentAdvisorProfile);
    const [modalName, setModalName] = useState(false);

    useEffect(() => {
        const { advisorId } = params;
        dispatch(fetchCurrentAdvisorProfile(advisorId));

        return () => {
            dispatch(setCurrentAdvisorProfile({}));
            dispatch(setCurrentAdvisorClients([]));
            dispatch(setCurrentAdvisorActivity({}));
        }

    // eslint-disable-next-line
    }, []);

    const importFPClientBtn_Click = () => {
        selectFPClientFileInput.current.inputRef.current.click();
    }

    const importFPClientInput_Change = async (e) => {
        if (e.target.files?.length === 0) {
            return;
        }
        setLoaderUpload(true);
        try {
            const confirm = window.confirm(`Please confirm you want to inject the client included in ${e.target.files[0].name} to ${advisorProfile.fullName}.`);
            if (confirm) {
                const {data} = await sysAPI.post('/generate-code');
                const form = new FormData();
                form.append('fpclientSrc', e.target.files[0]);
                form.append('code', data.code);
                form.append('targetAdvisorId', advisorProfile._id);

                // eslint-disable-next-line
                const {data: dataImported} = await fpalphaAPI.post('/advisor/clientlist/add-prefilled', form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                })
                alert(`Clients were added to ${advisorProfile.fullName} successfully.`);
                dispatch(fetchCurrentAdvisorProfile(advisorProfile._id))
            }
        } catch (error) {
            console.error({error})
            alert(`Something went wrong while adding ${e.target.files[0].name} clients to ${advisorProfile.fullName}.`);
        }
        setLoaderUpload(false)
    }

    const switchPaymentModality = () => {
        sysAPI.post(`/advisor/${params.advisorId }/update`, {paymentModality: advisorProfile.paymentModality === 'company' ? 'advisor': 'company'}).then(response => {
            window.alert('Switched Payment Modality, refresh to see changes')
        }).catch(err => {

        });
    }

    const dontRequestPaymentMethod = () => {
        sysAPI.post(`/advisor/${params.advisorId }/update-preferences`, {'settings.needsPaymentMethod': !advisorProfile.settings.needsPaymentMethod }).then(response => {
            window.alert('Updated preferences, refresh to see changes')
        }).catch(err => {

        });
    }

    return (
        <DashboardTemplate scope='advisors'>
            <Header as='h2'>
                <Icon name='user circle' />
                <Header.Content>
                    {advisorProfile?.fullName}
                    {advisorProfile?.hasActiveSession
                        ? <span style={{ color: 'green' }}> (online)</span>
                        : <span style={{ color: 'grey' }}> (offline)</span>
                    }
                    <Header.Subheader>
                        {advisorProfile?.email}
                    </Header.Subheader>
                </Header.Content>
            </Header>
            {/* {advisorProfile?.isOnFreeTrialPeriod &&
                <Button icon color='blue' labelPosition='left'>
                    <Icon size='large' name='calendar' />
                    Extend trial user license
                </Button>
            } */}
            <Button icon color='orange' labelPosition='left' onClick={() => setModalName('blockUser')}>
                <Icon size='large' name='lock' />
                Block account
            </Button>
            <Button icon color='green' labelPosition='left' onClick={() => setModalName('unblockUser')}>
                <Icon size='large' name='unlock' />
                Unblock account
            </Button>
            {/*advisorProfile?.isOnFreeTrialPeriod &&*/
                <Button icon color='violet' labelPosition='left' onClick={() => setModalName('fullAccessUser')}>
                    <Icon size='large' name='diamond' />
                    Grant full access
                </Button>
            }
            <Button icon color='blue' labelPosition='left' onClick={importFPClientBtn_Click} loading={loaderUpload}>
                <Icon name='file' />
                Import .fpclient
            </Button>
            <Button icon color='purple' labelPosition='left' onClick={switchPaymentModality}>
                <Icon size='large' name='exchange' />
                Switch payment modality
            </Button>
            <Button icon color='pink' labelPosition='left' onClick={dontRequestPaymentMethod}>
                <Icon size='large' name='credit card outline' />
                {advisorProfile.settings?.needsPaymentMethod ? "Don't request payment method" : 'Request payment method'}
            </Button>
            <Button icon color='red' labelPosition='left' onClick={() => setModalName('removeUser')}>
                <Icon size='large' name='remove user' />
                Delete advisor
            </Button>

            <EnableDisable2Fa advisorProfile={advisorProfile} />

            <EnableDisableFeatures advisorProfile={advisorProfile} />

            <ChangeNameOrEmail advisorProfile={advisorProfile} />

            <AdvisorMenu activeTab={activeTab} setActiveTab={setActiveTab} />
            <Segment attached='bottom'>
                <AdvisorOverviewTab activeTab={activeTab} />
                <AdvisorClientsTab activeTab={activeTab} />
                <AdvisorActivityTab activeTab={activeTab} />
            </Segment>

            <ConfirmAdvisorActionModal
                action={modalName}
                setAction={setModalName}
            />

            <Input type='file' ref={selectFPClientFileInput} style={{ display: 'none' }} onChange={importFPClientInput_Change} />
        </DashboardTemplate>
    )
}