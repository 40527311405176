import { useState, useEffect } from "react";
import {  useSelector } from "react-redux"
import { Button, Icon, Input, Modal, Select, Table } from "semantic-ui-react"
import { sysAPI } from "../../apis/SysAPI";

export const PropertiesModal = ({ open, closeBtn_Click, editingEnabled, area, sortAssets }) => {
    // const dispatch = useDispatch();
    const client = useSelector(state => state.currentClient);

    const [assets, setAssets] = useState([]);
    const [title, setTitle] = useState('');
    const [assetType, setAssetType] = useState('');
    const [edit, setEdit] = useState('');

    const [newTitle, setNewTitle] = useState('');
    const [newType, setNewType] = useState('');

    const sortProperties = (properties) => {
        const home = [];
        const auto = [];
        if (properties?.data?.home?.length > 0) {
            properties?.data?.home?.forEach(itm => {
                home.push({
                    _id: itm._id,
                    title: itm.title,
                    index: itm.index,
                    type: 'Home',
                });
            });
        }
        if (properties?.data?.auto?.length > 0) {
            properties?.data?.auto?.forEach(itm => {
                auto.push({
                    _id: itm._id,
                    title: itm.title,
                    index: itm.index,
                    type: 'Auto',
                });
            });
        }
        setAssets(area === 'home' ? home : auto);
    }

    const areaOptions = [];
    if (area === 'home') areaOptions.push({ key: 'home', value: 'Home', text: 'Home' });
    if (area === 'auto') areaOptions.push({ key: 'auto', value: 'Auto', text: 'Auto' });

    const getProperties = () => {
        sysAPI.get(`/insurance-snapshot/assets/${client?._id}`)
            .then(response => {
                sortProperties(response);
            });
    };

    useEffect(() => {
        if (client) getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, area]);

    const addBtn_Click = () => {
        sysAPI.post(
            `/insurance-snapshot/assets/`,
            {
                clientId: client?._id,
                area: newType.toLowerCase(),
                asset: { "title": newTitle },
            }
        ).then(response => {
            sortProperties(response);
            if (sortAssets) sortAssets(response);
        }).finally(() => {
            setNewTitle('');
            setNewType('');
        });
    };

    const removeBtn_Click = (asset) => {
        return () => {
            sysAPI.delete(
                `/insurance-snapshot/assets/${client?._id}/${asset?.type?.toLowerCase()}/${asset?.index}/${asset._id}`,
            ).then(response => {
                sortProperties(response);
                if (sortAssets) sortAssets(response);
            });
        }
    };

    const editBtn_Click = (assetId) => {
        return () => {
            const auxAsset = assets.find(c => c._id === assetId);
            setTitle(auxAsset.title);
            setAssetType(auxAsset.type);
            setEdit(assetId);
        }
    };

    const cancelEditBtn_Click = () => {
        return () => {
            setTitle('');
            setAssetType('');
            setEdit('');
        }
    };

    const saveEditBtn_Click = (assetId) => {
        return () => {
            sysAPI.patch(
                `/insurance-snapshot/assets/`,
                {
                    clientId: client?._id,
                    area: assetType.toLowerCase(),
                    index: assets.find(itm => itm._id === assetId).index,
                    title,
                }
            ).then(response => {
                sortProperties(response);
            }).finally(() => {
                setTitle('');
                setAssetType('');
                setEdit('');
            });
        }
    }

    return (
        <Modal open={open} size='large'>
            <Modal.Header>Properties</Modal.Header>
            <Modal.Content>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Property Name / Description</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {assets.map(asset => {
                            return (<Table.Row>
                                {asset._id !== edit && <>
                                    <Table.Cell>{asset.title}</Table.Cell>
                                    <Table.Cell>{asset.type}</Table.Cell>
                                </>}
                                {asset._id === edit && <>
                                    <Table.Cell><Input fluid value={title} onChange={e => setTitle(e.currentTarget.value)} /></Table.Cell>
                                    <Table.Cell>
                                        <Select
                                            fluid
                                            selection
                                            search
                                            options={areaOptions}
                                            value={assetType}
                                            onChange={(e, elem) => setAssetType(elem.value)}
                                        />
                                    </Table.Cell>
                                </>}
                                <Table.Cell>
                                    {editingEnabled && asset._id !== edit &&
                                        <div style={{ display: 'flex' }}>
                                            <Icon size="large" name="edit outline" color="grey" onClick={editBtn_Click(asset._id)} />
                                            <Icon size='large' name='remove' color='red' onClick={removeBtn_Click(asset)} />
                                        </div>
                                    }
                                    {asset._id === edit &&
                                        <div style={{ display: 'flex' }}>
                                            <Icon size="large" name="cancel" color="grey" onClick={cancelEditBtn_Click(asset._id)} />
                                            <Icon size="large" name="save" color="green" onClick={saveEditBtn_Click(asset._id)} />
                                        </div>
                                    }
                                </Table.Cell>
                            </Table.Row>)
                        })}
                        {editingEnabled &&
                            <Table.Row>
                                <Table.Cell><Input fluid value={newTitle} onChange={e => setNewTitle(e.currentTarget.value)} /></Table.Cell>
                                <Table.Cell>
                                    <Select
                                        fluid
                                        options={areaOptions}
                                        value={newType}
                                        onChange={(e, elem) => setNewType(elem.value)}
                                    />
                                </Table.Cell>
                                <Table.Cell style={{ width: '50px' }}>
                                    <Icon size='large' name='add circle' color='green' onClick={addBtn_Click} />
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={closeBtn_Click}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}