/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Modal, Input, Radio, Message, Segment, Button, Form, Container, Label, Select, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { fpalphaAPI } from '../../apis/FPAlphaAPI';
import { sysAPI } from '../../apis/SysAPI';
import { addAdvisorBuilder } from '../../builders/addAdvisorBuilder';
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentCompany } from '../../actions/company.actions';

const AREAS = {
    'no_area': 'Select an area to add...',
    'estate': 'Estate Planning',
    'auto': 'Auto',
    'crossborder': 'Cross Border',
    'disability': 'Disability',
    'home': 'Home',
    'medical': 'Medical',
    'identity': 'Identity',
    'student': 'Student Loan',
    'expense': 'Expense',
    'fulltax': 'Tax Planning',
    'ltc': 'LTC',
    'mortgage': 'Mortgage',
    'life': 'Life Planning',
    'divorce': 'Divorce Planning',
    'property': 'Property',
    'creditor': 'Creditor',
    'elder': 'Elder care',
};

const FEATURES = {
    'no_disabled_feature': 'Select a feature to disable...',
    'prospect': 'Prospect',
    'clientPortal': 'Client Portal',
};

export const AddAdvisorModal = ({ modalState, setModalState, preselectedCompany, companyType }) => {
    const dispatch = useDispatch();
    const [type, setType] = useState('trial');
    // eslint-disable-next-line
    const defaultAreas = ['fulltax', 'estate', 'home', 'auto']
    //const [licenseDuration, setLicenseDuration] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [enabledAreas, setEnabledAreas] = useState(defaultAreas);
    const [selectedAreaIdToAdd, setSelectedAreaIdToAdd] = useState('no_area');
    const [companies, setCompanies] = useState([]);
    const [pocs, setPocs] = useState([]);
    const [roles, setRoles] = useState([]);

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [poc, setPoc] = useState('');
    // eslint-disable-next-line
    const [role, setRole] = useState('');
    const [showPocInput, setShowPocInput] = useState(false);
    const [disabledFeatures, setDisabledFeatures] = useState([]);
    const [disabledFeatureIdToAdd, setDisabledFeatureIdToAdd] = useState('no_disabled_feature');
    const companyProfile = useSelector((state) => state.currentCompany);

    useEffect(() => {
        setType("trial")
        setFullName("");
        setEmail("");
        setPhone("");
        setCompany("");
        setRole("");
        setPoc("");
        setShowPocInput(false);
        setDisabledFeatures([]);
        setDisabledFeatureIdToAdd("no_disabled_feature");
        setEnabledAreas(['fulltax', 'estate', 'home', 'auto'])
    },[modalState])

    useEffect(() => {
        sysAPI.get('/companies').then(response => {
            setCompanies(response.data.companiesList);
            //console.log(response.data.companiesList);
        });
        sysAPI.get('/companies/pocs').then(response => {
            setPocs(response.data);
            //console.log(response.data);
        });
        sysAPI.get('/roles').then(response => {
            setRoles(response.data);
            //console.log(response.data);
        });
    }, []);

    const removeEnabledArea_Click = (areaId) => {
        return () => {
            const tempEnabledAreas = [...enabledAreas];
            tempEnabledAreas.splice(tempEnabledAreas.indexOf(areaId), 1);
            setEnabledAreas(tempEnabledAreas);
        }
    }

    const removeDisabledFeature_Click = (featureId) => {
        return () => {
            const tempDisabledFeatures = [...disabledFeatures];
            tempDisabledFeatures.splice(tempDisabledFeatures.indexOf(featureId), 1);
            setDisabledFeatures(tempDisabledFeatures);
        }
    }

    const addEnabledArea_Click = () => {
        if (selectedAreaIdToAdd === 'no_area' || enabledAreas.indexOf(selectedAreaIdToAdd) >= 0) return;

        const tempEnabledAreas = [...enabledAreas];
        tempEnabledAreas.push(selectedAreaIdToAdd);
        setEnabledAreas(tempEnabledAreas);
    }

    const addDisabledFeature_Click = () => {
        if (disabledFeatureIdToAdd === 'no_area' || disabledFeatures.indexOf(disabledFeatureIdToAdd) >= 0) return;

        const tempDisabledFeatures = [...disabledFeatures];
        tempDisabledFeatures.push(disabledFeatureIdToAdd);
        setDisabledFeatures(tempDisabledFeatures);
    }

    const createAccountBtn_Click = async () => {
        setShowLoader(true);

        // Create company, then add advisor

        //console.log({company: encodeURIComponent(company)})
        //* Method 1
        const companyResponse = await sysAPI.get(`/companies/verify?company=${encodeURIComponent(company)}`)

        //* Method 2
        /* const companyResponse = await sysAPI.get(`/companies/verify`,{
            params:{
                company
            }
        }) */

        //console.log(companyResponse);
        if (companyResponse.data === null) {
            setShowPocInput(true);
            if (poc !== '') {
                setShowLoader(true);
                // eslint-disable-next-line
                const companyResponse = await fpalphaAPI.post('/advisor/homepage/company', {
                    name: company,
                    poc: pocs.find(p => p.name === poc)?._id,
                    logo: 'https://storage.googleapis.com/fpalpha-assets/company%20logos/fpalpha/fpalpha1_CMYK.png'
                }).then(response => {
                    // console.log('Company created successfully.');
                    addAdvisor()
                    return response;
                }).catch(err => {
                    // console.error(err);
                    return err.response;
                });
            } else {
                setShowLoader(false);
                alert('Add POC for new company');
            }
        }
        else {
            addAdvisor()
        }
    }

    const getCompanyInfo = () => {
        //console.log({companyProfile});
        if(companyProfile?._id){
            console.log("Exist company, updating info...")
            dispatch(fetchCurrentCompany(companyProfile._id));
        }
    }

    const addAdvisor = async () => {
        const { _id: roleId } = roles.find(e => e.name === role);
        // console.log('role here', role)
        const payload = addAdvisorBuilder(email, fullName, company, roleId, phone, enabledAreas, disabledFeatures, type);
        // console.log(payload);
        try {
            // console.log('Doing request');
            // eslint-disable-next-line
            const createAdvisorResponse = await fpalphaAPI.post('/advisor/homepage/advisors', payload);
            alert(`${fullName}'s account was created successfully. `);
            setModalState(false);
            getCompanyInfo();
        }
        catch (error) {
            const message = error?.response?.data?.message || error?.message || "Oops... something is wrong..."
            console.log('Error :c');
            console.error(error);
            alert(message );
        }
        setShowLoader(false);
    }

    const areasSelectOptions = Object.entries(AREAS).map(area => ({ key: area[0], value: area[0], text: area[1] }));
    const disabledFeaturesOptions = Object.entries(FEATURES).map(feature => ({ key: feature[0], value: feature[0], text: feature[1] }));

    useEffect(() => {
        if (modalState && preselectedCompany) {
            setCompany(preselectedCompany);
            setType(companyType === 'purchased' ? 'licensed' : 'trial');
        }
    }, [modalState]);

    useEffect(() => {
        if(type === "trial"){
            setEnabledAreas(defaultAreas)
        }
        else{
            setEnabledAreas([])
        }
    },[type])

    return (
        <Modal size='tiny' open={modalState} onClose={() => setModalState(false)} onOpen={() => setModalState(true)}>
            <Modal.Header as='h3'>Create an Advisor Account</Modal.Header>
            <Modal.Content>
                <Segment>
                    <Form>
                        <Form.Group inline>
                            <Form.Field control={Radio} label='Trial' onChange={() => setType('trial')} checked={type === 'trial'} />
                            <Form.Field control={Radio} label='Licensed advisor' onChange={() => setType('licensed')} checked={type === 'licensed'} />
                        </Form.Group>
                        {/* {type === 'licensed' && (
                            <Form.Group inline>
                                <Form.Field control={Radio} label='Annual plan' onChange={() => setLicenseDuration('annual')} checked={licenseDuration === 'annual'} />
                                <Form.Field control={Radio} label='Quarterly plan' onChange={() => setLicenseDuration('quarterly')} checked={licenseDuration === 'quarterly'} />
                                <Form.Field control={Radio} label='No expiration' onChange={() => setLicenseDuration('eternal')} checked={licenseDuration === 'eternal'} />
                            </Form.Group>
                        )} */}
                        {type === 'trial' && (
                            <Message color='teal'>
                                This advisor will have 10 days of trial version.
                            </Message>
                        )}
                        {/* {type === 'licensed' && licenseDuration === 'eternal' && (
                            <Message color='red'>
                                License duration tracking will be disabled with the selected option.
                            </Message>
                        )} */}
                    </Form>
                </Segment>
                <Segment>
                    <Form>
                        <Form.Field control={Input} fluid icon='user' iconPosition='left' placeholder='Full name' value={fullName} onChange={e => setFullName(e.currentTarget.value)} />
                        <Form.Field control={Input} fluid icon='at' iconPosition='left' placeholder='Email address' value={email} onChange={e => setEmail(e.currentTarget.value)}  />
                        <Form.Field control={Input} fluid icon='phone' iconPosition='left' placeholder='Phone number' value={phone} onChange={e => setPhone(e.currentTarget.value)}  />
                        <Form.Field control={Input} fluid list='companies' icon='building' iconPosition='left' placeholder='Company' value={company} onChange={e => setCompany(e.currentTarget.value)}  />
                        <datalist id='companies'>
                            {companies.map(company => <option key={company} value={company}>{company}</option>)}
                        </datalist>
                        {showPocInput &&
                            <>
                                <div style={{marginLeft: '15px'}}>
                                <Form.Field control={Input} fluid list='pocs' icon='building' iconPosition='left' placeholder='POC' value={poc} onChange={e => setPoc(pocs.find(poc => poc.name === e.currentTarget.value)?.name)}  />
                                </div>
                                <datalist id='pocs'>
                                    {pocs.map(poc => <option key={poc.name} value={poc.name}>{poc.name}</option>)}
                                </datalist>
                            </>
                        }
                        <Form.Field control={Input} fluid list='roles' icon='user' iconPosition='left' placeholder='Role' value={role} onChange={e => setRole(e.currentTarget.value)}  />
                        <datalist id='roles'>
                            {roles.map(role => <option key={role._id} value={role.name}>{role.description}</option>)}
                        </datalist>
                    </Form>
                </Segment>
                {/* <Segment style={{ display: type === 'licensed' ? 'none' : 'block' }}> */}
                <Segment>
                    <Form>
                        <Form.Group widths='equal' inline>
                            <Form.Field fluid control={Select} options={areasSelectOptions} value={selectedAreaIdToAdd} onChange={(e, elem) => setSelectedAreaIdToAdd(elem.value)} />
                            <Button onClick={addEnabledArea_Click} color='blue'>Add</Button>
                        </Form.Group>
                        <Label.Group size='large' fluid>
                            {enabledAreas.map(areaId => <Label key={areaId}>{AREAS[areaId]}<Icon onClick={removeEnabledArea_Click(areaId)} name='delete' /></Label>)}
                        </Label.Group>
                    </Form>
                </Segment>
                <Segment>
                    <Form>
                        <Form.Group widths='equal' inline>
                            <Form.Field fluid control={Select} options={disabledFeaturesOptions} value={disabledFeatureIdToAdd} onChange={(e, elem) => setDisabledFeatureIdToAdd(elem.value)} />
                            <Button onClick={addDisabledFeature_Click} color='blue'>Add</Button>
                        </Form.Group>
                        <Label.Group size='large' fluid>
                            {disabledFeatures.map(feature => <Label key={feature}>{FEATURES[feature]}<Icon onClick={removeDisabledFeature_Click(feature)} name='delete' /></Label>)}
                        </Label.Group>
                    </Form>
                </Segment>
                <Container textAlign='right'>
                    <Button color='grey' onClick={() => setModalState(false)}>Cancel</Button>
                    <Button color='green' onClick={createAccountBtn_Click}>Create account</Button>
                </Container>
                <Dimmer active={showLoader}>
                    <Loader size='large' indeterminate>Creating advisor account...</Loader>
                </Dimmer>
            </Modal.Content>
        </Modal>
    )
}