/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState, useRef } from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Label,
    Tab,
    Table,
    Icon,
    Input,
    Dropdown,
    Modal,
    Form,
    TextArea,
    Message,
} from "semantic-ui-react"
import { sysAPI } from "../../apis/SysAPI";
import { ComponentTemplate } from "../../templates/ComponentTemplate";
import LoaderCustom from "./EstateManualInputRequestsTab/LoaderCustom";


const ContinueReviewModal = ({ clientId, index, hideModal, showModal, refreshList }) => {
    const messageArea = useRef();
    const [message, setMessage] = useState('');
    const [unfilled, setUnfilled] = useState(false);

    const handleMessage = e => {
        setMessage(e.target.value);
    };

    const continueReview = () => {
        if (!message || message.length <= 5) {
        setUnfilled('You are required to enter a reason for submitting on hold.');
        return true;
        }

        console.log(clientId)
        console.log(index)

        if (unfilled) setUnfilled(false);
        sysAPI.post('/estate-manual-input/continuereview', {clientId, index, message})
        .then(res => {
            if (res.status === 200) {
            refreshList(prev => (prev + 1));
            hideModal(null);
            }
        })
        .catch(err => setUnfilled(err.message));
    };

    useEffect(() => {
        messageArea.current.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <Modal open={showModal !== null}>
            <Modal.Header>Why do you want to continue reviewing this E.M.I.R.?</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <TextArea ref={messageArea} value={message} onChange={handleMessage} />
                    </Form.Field>
                </Form>
                <p style={{color: 'red', marginTop: '0.8em'}}>{unfilled || ''}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => hideModal(null)}>Cancel</Button>
                <Button
                    onClick={continueReview}
                    color="purple"
                >
                    Send to hold
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

const RequestsTable = ({ stage, requests, setSort, setDirection, sort, direction, refreshList }) => {
    const me = useSelector(state => state.me);
    const [showContinueReviewModal, setShowContinueReviewModal] = useState(false);
    const [clientId, setclientId] = useState(undefined);
    const [index, setIndex] = useState(undefined);

    let showButtons = false
    /* if(me?.permissions?.length > 0){
        if(me?.permissions?.includes("manual_inputs:p&c_manual_input_view")){
            showButtons = true;
        }
    } */

    if(me?.permissions?.includes("manual_inputs:button_start_reviewing") || me?.permissions?.includes("manual_inputs:p&c_manual_input_view")){
        showButtons = true;
    }

    const reviewEstateBtn_Click = (clientId) => {
        return () => {
            sysAPI.post(`/insurance-snapshot/review`, {
                clientId,
                index,
                area: 'auto'
            }).then(response => {
                window.location.replace(`/dashboard/insurance-snapshot/${clientId}/auto`)
                console.log(response.data);
            }).catch(err => {
                console.error(err.response);
            });
        }
    }

    const getLabel = (request) => {
        switch (request.status) {
            case 'Pending': return <Label color='red'>PENDING</Label>;
            case 'In review': return <Label color='blue'>IN REVIEW</Label>;
            // case 'On Hold': return <Label color='purple'>ON HOLD</Label>;
            case 'Ready': return <Label color='green'>READY</Label>;
            default: break;
        }
    }

    const sortColumn = async (column) => {
        if (column === sort) {
            setDirection(direction === 'ASC' ? 'DESC' : 'ASC');
            return
        }

        setSort(column);
        setDirection('DESC');
    }

    const handleContinue = async (client, ind) => {
        setclientId(client);
        setIndex(ind);
        setShowContinueReviewModal(true);
    }


    return (<>
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Client Name</Table.HeaderCell>
                    <Table.HeaderCell>Requested by</Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'company' ? 'active' : ''}`}
                        onClick={() => sortColumn('company')}
                    >
                        Company
                        {(sort === 'company' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'company' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'company' && <Icon name="sort" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'poc' ? 'active' : ''}`}
                        onClick={() => sortColumn('poc')}
                    >
                        POC
                        {(sort === 'poc' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'poc' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'poc' && <Icon name="sort" />}
                    </Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell
                        className={`table-header__order ${sort === 'requestDate' ? 'active' : ''}`}
                        onClick={() => sortColumn('requestDate')}
                    >
                        Request Date
                        {(sort === 'requestDate' && direction === 'ASC') && <Icon name="sort up" />}
                        {(sort === 'requestDate' && direction === 'DESC') && <Icon name="sort down" />}
                        {sort !== 'requestDate' && <Icon name="sort" />}
                    </Table.HeaderCell>

                    {stage === 'Ready' &&
                    <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'finishDate' ? 'active' : ''}`}
                            onClick={() => sortColumn('finishDate')}
                        >
                            Finish Date
                            {(sort === 'finishDate' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'finishDate' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'finishDate' && <Icon name="sort" />}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'etc' ? 'active' : ''}`}
                            onClick={() => sortColumn('etc')}
                        >
                            ETC
                            {(sort === 'etc' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'etc' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'etc' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'In review' && <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeInReview' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeInReview')}
                        >
                            Time In Review
                            {(sort === 'timeInReview' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeInReview' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeInReview' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'Pending' &&
                    <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeInPending' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeInPending')}
                        >
                            Time In Pending
                            {(sort === 'timeInPending' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeInPending' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeInPending' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    {stage === 'Peer review' &&
                    <>
                        <Table.HeaderCell>Time In Review</Table.HeaderCell>
                    </>}

                    {(stage === 'In review' || stage === 'Peer review') && <>
                        <Table.HeaderCell
                            className={`table-header__order ${sort === 'timeSinceRequest' ? 'active' : ''}`}
                            onClick={() => sortColumn('timeSinceRequest')}
                        >
                            Time Since Request
                            {(sort === 'timeSinceRequest' && direction === 'ASC') && <Icon name="sort up" />}
                            {(sort === 'timeSinceRequest' && direction === 'DESC') && <Icon name="sort down" />}
                            {sort !== 'timeSinceRequest' && <Icon name="sort" />}
                        </Table.HeaderCell>
                    </>}

                    <Table.HeaderCell>Reviewer</Table.HeaderCell>
                    {me?.permissions?.includes('manual_inputs:reviewerGroup_*') &&
                        <Table.HeaderCell>Assigned to</Table.HeaderCell>
                    }

                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {requests.map((request, index) => {
                    return <Table.Row key={index}>
                        <Table.Cell>{request.client.displayName}</Table.Cell>
                        <Table.Cell>
                            {request.advisor.fullName}/
                            {request.advisor.isOnFreeTrialPeriod ? 'Trial' : request.advisor.hasSubscription  ? 'Purchased' : 'Expired'}
                        </Table.Cell>
                        <Table.Cell>{request.advisor.nameCompany || request.advisor.companyName}</Table.Cell>
                        <Table.Cell>{request.advisor.poc}</Table.Cell>
                        <Table.Cell>{getLabel(request)}</Table.Cell>
                        <Table.Cell>{moment(request.date).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        {
                            stage === 'Ready' &&
                            <Table.Cell>{moment(request.finishReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        }
                        {/*{
                            stage === 'In review' &&
                            <Table.Cell>{moment(request.startReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        }
                        {
                            stage === 'Peer review' &&
                            <Table.Cell>{moment(request.finishPeerReviewDate).format('MM/DD/YYYY HH:mm:ss')}</Table.Cell>
                        } */}

                        <Table.Cell>{request.etc}</Table.Cell>

                        {(stage === 'In review' || stage === 'Peer review') &&
                            <Table.Cell>{request.timeSinceRequest}</Table.Cell>}

                        <Table.Cell>{request.reviewer?.firstName ? `${request.reviewer.firstName} ${request.reviewer.lastName}` : 'N/A'}{request.peerReviewer?.firstName ? ` / ${request.peerReviewer.firstName} ${request.peerReviewer.lastName}` : ''}</Table.Cell>
                        {me?.permissions?.includes('manual_inputs:reviewerGroup_*') &&
                            <Table.Cell>{request.reviewerGroup.join(', ')}</Table.Cell>
                        }
                        <Table.Cell>
                            {request?.status === 'Pending' && request.client._id && showButtons &&
                                // <Link to={`/dashboard/estate-manual-input/${request.client._id}/${request.index}`}>
                                    <Button color='green' onClick={reviewEstateBtn_Click(request.client._id)}>
                                        Start Reviewing
                                    </Button>
                                // </Link>
                            }
                            {request.status === 'In review' && (request.reviewer._id === me._id ) &&
                                <Link to={`/dashboard/insurance-snapshot/${request.client._id}/auto`}>
                                    <Button color='blue'>
                                        Continue Reviewing
                                    </Button>
                                </Link>
                            }
                            {request.status === 'On Hold' && me.permissions.includes("manual_inputs:button_view_document") &&
                                <Button color='purple' onClick={() => handleContinue(request.client._id, request.index)}>
                                    Finish Hold
                                </Button>
                            }
                            {request.status === 'Peer review' && showButtons &&
                                <Link to={`/dashboard/insurance-snapshot/${request.client._id}/auto`}>
                                    <Button color='orange' onClick={reviewEstateBtn_Click(request.client._id, request.index)}>
                                        Finish Reviewing
                                    </Button>
                                </Link>
                            }
                            {(((request.status === 'In review' || request.status === 'Peer review') &&  me.permissions.includes("manual_inputs:button_view_document")) || request.status === 'Ready') &&
                                <Link to={`/dashboard/insurance-snapshot/${request.client._id}/auto`}>
                                    <Button>
                                        View
                                    </Button>
                                </Link>
                            }
                        </Table.Cell>
                    </Table.Row>
                })}
            </Table.Body>
        </Table>
        {showContinueReviewModal &&
            <ContinueReviewModal
                hideModal={setShowContinueReviewModal}
                showModal={showContinueReviewModal}
                refreshList={refreshList}
                clientId={clientId}
                index={index}
            />}
    </>)
}

export const AutoRequestsTab = () => {
    const [requests, setRequests] = useState([]);
    const [pending, setPending] = useState([]);
    const [inReview, setInReview] = useState([]);
    //const [onHold, setOnHold] = useState([]);
    const [ready, setReady] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    // eslint-disable-next-line
    const [changeMaps, setChangeMaps] = useState(0);
    const [fromDate, setFromDate] = useState(moment().add(-1, "month").format("YYYY-MM-DD"));
    const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
    const [sort, setSort] = useState('requestDate');
    const [direction, setDirection] = useState('ASC');
    // eslint-disable-next-line
    const [initDate, setInitDate] = useState('');
    const [companiesList, setCompaniesList] = useState([]);
    const [companySelected, setCompanySelected] = useState('');
    const [pocsLists, setPocsLists] = useState([]);
    const [pocSelected, setPocSelected] = useState('');
    // eslint-disable-next-line
    const [refreshList, setRefreshList] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    //const me = useSelector(state => state.me);

    const getRequests = async () => {
        setIsLoading(true);
        try {
            const params__ = {
                area: "auto"
            }
            if(sort){
                params__.filter = sort;
                params__.order = direction;
            }
            if(fromDate){
                params__.initDate = fromDate;
            }
            if(toDate){
                params__.finishDate = toDate;
            }

            if(companySelected !== '' && companySelected !== 'N/A'){
                params__.tabFilter = "company";
                params__.tabValue = companySelected;
            }

            if(pocSelected !== '' && pocSelected !== 'N/A'){
                params__.tabFilter = "poc";
                params__.tabValue = pocSelected;
            }
            const {data} = await sysAPI.get(`/insurance-snapshot/requests`,{
                params: params__
            });
            setRequests(data.requests);
            if(data.requests.length <= 0){
                alert('No matches found for your current filters.');
            }
        } catch (error) {
            console.log({error});
            alert(error?.response?.data?.message || error?.message || "something was wrong...")
        }

        setIsLoading(false);
    };

    const getInitData = async () => {
        try {
            const response = await sysAPI.get(`/companies/emi`)
            const { companiesList } = response.data;
            const list = companiesList.map(company => ({ key: company, text: company, value: company }))
            list.unshift({ key: 'all', text: 'All', value: '' });
            setCompaniesList(list);

            const response_ = await sysAPI.get('/companies/pocs')
            const pocs = response_.data.map(advisor => ({ key: advisor._id, text: advisor.name, value: advisor.name}));
            pocs.unshift({ key: 'all', text: 'All', value: '' });
            setPocsLists(pocs);
        } catch (error) {
            console.log({error});
        }
    }

    useEffect(() => {
        getRequests()
    }, [sort, direction, initDate]);

    useEffect(() => {
        if (refreshList > 0) {
            getRequests()
        }
    }, [refreshList]);

    useEffect(() => {
        if (requests.length >= 0) {
            const mapPending = requests.filter(r => r.status === 'Pending') || [];
            const mapInReview = requests.filter(r => r.status === 'In review') || [];
            // const mapOnHold = requests.filter(r => r.status === 'On Hold') || [];
            const mapReady = requests.filter(r => r.status === 'Ready') || [];

            setPending(mapPending);
            setInReview(mapInReview);
            // setOnHold(mapOnHold);
            setReady(mapReady);
        }
    }, [requests]);

    useEffect(() => {
        if (changeMaps >= 1) {
            setIsLoading(true);
            getRequests();
        }
    }, [changeMaps]);

    useEffect(() => {
        getInitData()
    }, []);

    useEffect(() => {
        console.log({fromDate, toDate})
        const fromDateMoment = moment(fromDate);
        const toDateMoment = moment(toDate);
        if(toDateMoment.diff(fromDateMoment, "months") > 3){
            setErrorMessage("We cannot search between dates with a difference of more than 3 months");
        }else{
            setErrorMessage("")
        }
    },[fromDate, toDate])

    const countRequests = (status) => {
        return requests?.filter(r => r.status === status).length;
    }

    const panes = () => ([
        { menuItem: `Pending (${countRequests('Pending')})`, render: () => <RequestsTable requests={pending} stage='Pending' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        { menuItem: `In Review (${countRequests('In review')})`, render: () => <RequestsTable requests={inReview} stage='In review' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
        // { menuItem: `On Hold (${countRequests('On Hold')})`, render: () => <RequestsTable requests={onHold} stage='On Hold' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} refreshList={setRefreshList} /> },
        { menuItem: `Ready (${countRequests('Ready')})`, render: () => <RequestsTable requests={ready} stage='Ready' setSort={setSort} setDirection={setDirection} sort={sort} direction={direction} /> },
    ]);

    return (
        <ComponentTemplate scope={'insurance_manual_input'} permission="manual_inputs:p&c_manual_input_view">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <div>
                    <div>Filter by Company: </div>
                    <Dropdown
                        placeholder='Filter by Company'
                        selection
                        search
                        options={companiesList}
                        value={companySelected}
                        onChange={(e, { value }) => {setPocSelected(''); setCompanySelected(value)}}
                    />
                </div>
                <div>
                    <div>Filter by POC: </div>
                    <Dropdown
                        placeholder='Filter by POC'
                        selection
                        search
                        options={pocsLists}
                        value={pocSelected}
                        onChange={(e, { value }) => {setCompanySelected(''); setPocSelected(value)}}
                    />
                </div>
                <div>
                    <div>
                        From Date:
                    </div>
                    <Input
                        value={fromDate}
                        onChange={e => setFromDate(e.currentTarget.value)}
                        icon='calendar alternate outline'
                        iconPosition='left'
                        type='date'
                        min='2022-05-31'
                    />
                </div>
                <div>
                    <div>
                        To Date:
                    </div>
                    <Input
                        value={toDate}
                        onChange={e => setToDate(e.currentTarget.value)}
                        icon='calendar alternate outline'
                        iconPosition='left'
                        type='date'
                    />
                </div>
                <Button onClick={() => getRequests()} disabled={errorMessage}>Filter</Button>
            </div>
            {errorMessage && 
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <Message color="red" size="mini"> {errorMessage} </Message>
                </div>
            }

            <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes()}
            />
            {isLoading && <LoaderCustom />}
        </ComponentTemplate>
    )
}